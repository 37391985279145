import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import styles from "./blog-post.module.css";

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <article className={styles.blogPostContainer}>
        <section className={styles.postContent}>
          <h1 className={styles.postTitle}>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
